// -----------------------------------------------------------------------------
//
// This is a generated file. Do not modify it, modify the generator instead.
//
// -----------------------------------------------------------------------------

export type GenTheme_ThemeOptions = {
  Brand?: "Alpaca" | "Adaptive"
  Theme?: "Adaptive-Light" | "Adaptive-Dark" | "Alpaca-Light" | "Alpaca-Dark"
}
export type GenTheme_Theme = ReturnType<typeof resolveTheme>
export type GenTheme_Variables = GenTheme_Theme["variables"]
export type GenTheme_TextStyles = Record<
  GenTheme_TextStyleName,
  GenTheme_TextStyleDefinition
>
export type GenTheme_TextStyleName =
  | "typography/h1"
  | "typography/h2"
  | "typography/h3"
  | "typography/h4"
  | "typography/h5"
  | "typography/h6"
  | "typography/body1"
  | "typography/body2"
  | "typography/subtitle1"
  | "typography/subtitle2"
  | "typography/overline"
  | "typography/caption"
  | "alert/title"
  | "alert/description"
  | "badge/label"
  | "bottomNavigation/activeLabel"
  | "button/large"
  | "button/medium"
  | "button/small"
  | "tooltip/label"
  | "chip/label"
  | "datePicker/currentMonth"
  | "input/input"
  | "input/label"
  | "input/helper"
  | "form/label"
  | "list/subheader"
  | "menu/itemDefault"
  | "menu/itemDense"
  | "table/header"
  | "dataGrid/aggregationColumnHeaderLabel"
  | "_library/heading"
  | "avatar/medium"
  | "avatar/small"
  | "avatar/large"
  | "cardHeader/large"
  | "cardHeader/medium"
  | "cardHeader/small"

export type GenTheme_TextStyleDefinition = {
  fontFamily: string
  fontSize: number
  fontStretch: string
  fontStyle: string
  fontWeight: number
  letterSpacing: number | string
  lineHeight: number | string
  marginBlockEnd: number
  marginInlineStart: number
  textDecoration: TT_TextDecoration
  textTransform: TT_TextTransform
}

export const resolveTheme = (options: GenTheme_ThemeOptions = {}) => {
  const textStyles: GenTheme_TextStyles = {
    "typography/h1": {
      fontFamily: "Roboto",
      fontSize: 20,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 300,
      letterSpacing: 0,
      lineHeight: "150%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "typography/h2": {
      fontFamily: "Roboto",
      fontSize: 16,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: -0.5,
      lineHeight: "120.00000476837158%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "typography/h3": {
      fontFamily: "Roboto",
      fontSize: 15,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: "116.70000553131104%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "typography/h4": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: 0.25,
      lineHeight: "123.50000143051147%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "typography/h5": {
      fontFamily: "Roboto",
      fontSize: 13,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: "133.39999914169312%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "typography/h6": {
      fontFamily: "Roboto",
      fontSize: 12,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: 0.15000000596046448,
      lineHeight: "160.0000023841858%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "typography/body1": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0.15000000596046448,
      lineHeight: "150%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "typography/body2": {
      fontFamily: "Roboto",
      fontSize: 12,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0.17000000178813934,
      lineHeight: "142.99999475479126%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "typography/subtitle1": {
      fontFamily: "Roboto",
      fontSize: 16,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0.15000000596046448,
      lineHeight: "175%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "typography/subtitle2": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: 0.10000000149011612,
      lineHeight: "157.00000524520874%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "typography/overline": {
      fontFamily: "Roboto",
      fontSize: 10,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "266.00000858306885%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "typography/caption": {
      fontFamily: "Roboto",
      fontSize: 12,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 800,
      letterSpacing: 0.4000000059604645,
      lineHeight: "165.9999966621399%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "alert/title": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: 0.15000000596046448,
      lineHeight: "150%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "alert/description": {
      fontFamily: "Roboto",
      fontSize: 12,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: 0.15000000596046448,
      lineHeight: "142.99999475479126%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "badge/label": {
      fontFamily: "Roboto",
      fontSize: 12,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: 0.14000000059604645,
      lineHeight: 20,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "bottomNavigation/activeLabel": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0.4000000059604645,
      lineHeight: "165.9999966621399%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "button/large": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: 0.46000000834465027,
      lineHeight: 26,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "button/medium": {
      fontFamily: "Roboto",
      fontSize: 12,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: 0.4000000059604645,
      lineHeight: 24,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "button/small": {
      fontFamily: "Roboto",
      fontSize: 10,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: 0.46000000834465027,
      lineHeight: 22,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "tooltip/label": {
      fontFamily: "Roboto",
      fontSize: 10,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 14,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "chip/label": {
      fontFamily: "Roboto",
      fontSize: 12,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0.1599999964237213,
      lineHeight: 18,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "datePicker/currentMonth": {
      fontFamily: "Roboto",
      fontSize: 16,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: 0.15000000596046448,
      lineHeight: "150%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "input/input": {
      fontFamily: "Inter",
      fontSize: 12,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0.15000000596046448,
      lineHeight: 24,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "input/label": {
      fontFamily: "Roboto",
      fontSize: 10,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0.15000000596046448,
      lineHeight: 12,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "input/helper": {
      fontFamily: "Roboto",
      fontSize: 12,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0.4000000059604645,
      lineHeight: "165.9999966621399%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "form/label": {
      fontFamily: "Roboto",
      fontSize: 12,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: 0.15000000596046448,
      lineHeight: 12,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "list/subheader": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: 0.10000000149011612,
      lineHeight: 48,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "menu/itemDefault": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0.15000000596046448,
      lineHeight: "150%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "menu/itemDense": {
      fontFamily: "Roboto",
      fontSize: 12,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0.17000000178813934,
      lineHeight: 24,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "table/header": {
      fontFamily: "Roboto",
      fontSize: 10,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: 0.17000000178813934,
      lineHeight: 24,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "uppercase",
    },
    "dataGrid/aggregationColumnHeaderLabel": {
      fontFamily: "Roboto",
      fontSize: 12,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 500,
      letterSpacing: 0.15000000596046448,
      lineHeight: 12,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "uppercase",
    },
    "_library/heading": {
      fontFamily: "Roboto",
      fontSize: 64,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 600,
      letterSpacing: -1.5,
      lineHeight: "116.70000553131104%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "avatar/medium": {
      fontFamily: "Roboto",
      fontSize: 16,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0.14000000059604645,
      lineHeight: 20,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "avatar/small": {
      fontFamily: "Roboto",
      fontSize: 12,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0.14000000059604645,
      lineHeight: 20,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "avatar/large": {
      fontFamily: "Roboto",
      fontSize: 20,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0.14000000059604645,
      lineHeight: 20,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "cardHeader/large": {
      fontFamily: "Roboto",
      fontSize: 24,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: "133.39999914169312%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "cardHeader/medium": {
      fontFamily: "Roboto",
      fontSize: 16,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: "133.39999914169312%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
    "cardHeader/small": {
      fontFamily: "Roboto",
      fontSize: 12,
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: "133.39999914169312%",
      marginBlockEnd: 0,
      marginInlineStart: 0,
      textDecoration: "none",
      textTransform: "none",
    },
  }

  // -------------------------------------
  //
  // BEGIN of HIGHLY UNSAFE CODE
  // presumably correct by construction 🤷🏻‍♂️
  //
  // -------------------------------------

  Object.entries(textStyles).forEach(([styleName, styleDefinition]) => {
    Object.keys(styleDefinition).forEach((fieldName) => {
      const binding = textStyleBindings[styleName]?.[fieldName]
      if (binding) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const targetMode = (options as any)[binding.collectionName]

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dereferencedValue = (variablesByCollection as any)[
          binding.collectionName
        ]?.modes?.[targetMode]?.[binding.variableName]

        if (dereferencedValue !== undefined) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ;(textStyles as any)[styleName][fieldName].value = dereferencedValue
        }
      }
    })
  })

  // -------------------------------------
  //
  // END of HIGHLY UNSAFE CODE
  //
  // -------------------------------------

  return {
    textStyles,
    variables: {
      Brand:
        variablesByCollection["Brand"].modes[
          options["Brand"] ?? variablesByCollection["Brand"].defaultModeName
        ],
      Theme:
        variablesByCollection["Theme"].modes[
          options["Theme"] ?? variablesByCollection["Theme"].defaultModeName
        ],
    },
  }
}
const textStyleBindings: GenTheme_TextStyleBindings = {
  "typography/h1": {},
  "typography/h2": {},
  "typography/h3": {},
  "typography/h4": {},
  "typography/h5": {},
  "typography/h6": {},
  "typography/body1": {},
  "typography/body2": {},
  "typography/subtitle1": {},
  "typography/subtitle2": {},
  "typography/overline": {},
  "typography/caption": {},
  "alert/title": {},
  "alert/description": {},
  "badge/label": {},
  "bottomNavigation/activeLabel": {},
  "button/large": {},
  "button/medium": {},
  "button/small": {},
  "tooltip/label": {},
  "chip/label": {},
  "datePicker/currentMonth": {},
  "input/input": {},
  "input/label": {},
  "input/helper": {},
  "form/label": {},
  "list/subheader": {},
  "menu/itemDefault": {},
  "menu/itemDense": {},
  "table/header": {},
  "dataGrid/aggregationColumnHeaderLabel": {},
  "_library/heading": {},
  "avatar/medium": {},
  "avatar/small": {},
  "avatar/large": {},
  "cardHeader/large": {},
  "cardHeader/medium": {},
  "cardHeader/small": {},
}
const variablesByCollection: GenTheme_VariablesByCollection = {
  Brand: {
    modeNames: ["Alpaca", "Adaptive"],
    defaultModeName: "Alpaca",
    modes: {
      Alpaca: {
        "brd/controller/spacing/base": 4,
        "brd/controller/sizing/base": 4,
        "brd/controller/sizing/xs": 24,
        "brd/controller/sizing/sm": 32,
        "brd/controller/sizing/md": 40,
        "brd/controller/sizing/lg": 48,
        "brd/controller/borderRadius/default": 12,
        "brd/controller/borderRadius/none": 0,
        "brd/controller/borderRadius/sm": 4,
        "brd/controller/borderRadius/md": 8,
        "brd/controller/borderRadius/lg": 12,
        "brd/controller/borderRadius/full": 999,
        "brd/controllers/brand/base": "#facc15",
        "brd/controllers/mainUI/dark/background": "#0a0a0a",
        "brd/controllers/mainUI/light/background": "#a3a3a3",
        "brd/controllers/mainUI/dark/text-base": "#ffffff",
        "brd/controllers/mainUI/light/text-base": "#0a0a0a",
        "brd/controllers/functionalColors/info": "#0284c7",
        "brd/controllers/functionalColors/error": "#e11d48",
        "brd/controllers/functionalColors/success": "#16a34a",
        "brd/controllers/functionalColors/warning": "#ea580c",
        "brd/controllers/finance/positive": "#059669",
        "brd/controllers/finance/negative": "#dc2626",
        "brd/controllers/finance/buy": "#2563eb",
        "brd/controllers/finance/sell": "#dc2626",
        "brd/controllers/borderWidth": 1,
        "brd/controllers/colorSplash/light/primary": "#000000",
        "brd/controllers/colorSplash/dark/primary": "#facc15",
        "brd/controllers/colorSplash/light/secondary": "#737373",
        "brd/controllers/colorSplash/dark/secondary": "#171717",
        "brd/controllers/colorSplash/light/neutral": "#737373",
        "brd/controllers/colorSplash/dark/neutral": "#737373",
        "brd/controllers/colorSplash/light/primary-text-base": "#ffffff",
        "brd/controllers/colorSplash/dark/primary-text-base": "#131313",
      },
      Adaptive: {
        "brd/controller/spacing/base": 4,
        "brd/controller/sizing/base": 4,
        "brd/controller/sizing/xs": 24,
        "brd/controller/sizing/sm": 32,
        "brd/controller/sizing/md": 40,
        "brd/controller/sizing/lg": 48,
        "brd/controller/borderRadius/default": 4,
        "brd/controller/borderRadius/none": 0,
        "brd/controller/borderRadius/sm": 4,
        "brd/controller/borderRadius/md": 8,
        "brd/controller/borderRadius/lg": 12,
        "brd/controller/borderRadius/full": 999,
        "brd/controllers/brand/base": "#3b82f6",
        "brd/controllers/mainUI/dark/background": "#161a23",
        "brd/controllers/mainUI/light/background": "#acb5c8",
        "brd/controllers/mainUI/dark/text-base": "#ffffff",
        "brd/controllers/mainUI/light/text-base": "#161a23",
        "brd/controllers/functionalColors/info": "#0284c7",
        "brd/controllers/functionalColors/error": "#e11d48",
        "brd/controllers/functionalColors/success": "#16a34a",
        "brd/controllers/functionalColors/warning": "#ea580c",
        "brd/controllers/finance/positive": "#059669",
        "brd/controllers/finance/negative": "#dc2626",
        "brd/controllers/finance/buy": "#2563eb",
        "brd/controllers/finance/sell": "#dc2626",
        "brd/controllers/borderWidth": 2,
        "brd/controllers/colorSplash/light/primary": "#facc15",
        "brd/controllers/colorSplash/dark/primary": "#facc15",
        "brd/controllers/colorSplash/light/secondary": "#161a23",
        "brd/controllers/colorSplash/dark/secondary": "#1f242e",
        "brd/controllers/colorSplash/light/neutral": "#737373",
        "brd/controllers/colorSplash/dark/neutral": "#737373",
        "brd/controllers/colorSplash/light/primary-text-base": "#ffffff",
        "brd/controllers/colorSplash/dark/primary-text-base": "#ffffff",
      },
    },
  },
  Theme: {
    modeNames: [
      "Adaptive-Light",
      "Adaptive-Dark",
      "Alpaca-Light",
      "Alpaca-Dark",
    ],
    defaultModeName: "Adaptive-Light",
    modes: {
      "Adaptive-Light": {
        "1": 8,
        "2": 16,
        "3": 24,
        "4": 32,
        "5": 40,
        "6": 48,
        "7": 56,
        "8": 64,
        "9": 72,
        "10": 80,
        "11": 88,
        "12": 96,
        "text/primary": "#161a23de",
        "text/secondary": "#161a2399",
        "text/disabled": "#161a2361",
        "text/hover": "#161a230a",
        "text/selected": "#161a2314",
        "text/focus": "#161a231f",
        "text/focusVisible": "#161a234d",
        "primary/main": "#3d82f6",
        "primary/dark": "#0a57dc",
        "primary/light": "#689df8",
        "primary/contrastText": "#ffffff",
        "primary/hover": "#3d81f60a",
        "primary/selected": "#3d81f614",
        "primary/focus": "#3d81f61f",
        "primary/focusVisible": "#3d81f64d",
        "primary/outlinedBorder": "#3d81f680",
        "secondary/main": "#161a23",
        "secondary/dark": "#020304",
        "secondary/light": "#3c4760",
        "secondary/contrastText": "#ffffff",
        "secondary/hover": "#161a230a",
        "secondary/selected": "#161a2314",
        "secondary/focus": "#161a231f",
        "secondary/focusVisible": "#161a234d",
        "secondary/outlinedBorder": "#161a2380",
        "action/active": "#161a238f",
        "action/hover": "#161a230a",
        "action/selected": "#161a2314",
        "action/focus": "#161a231f",
        "action/disabled": "#161a2361",
        "action/disabledBackground": "#161a231f",
        "error/main": "#e11d48",
        "error/dark": "#a91636",
        "error/light": "#e84e6f",
        "error/contrastText": "#ffffff",
        "error/hover": "#e11d470a",
        "error/selected": "#e11d4714",
        "error/focusVisible": "#e11d474d",
        "error/outlinedBorder": "#e11d4780",
        "warning/main": "#ea580c",
        "warning/dark": "#b04209",
        "warning/light": "#f57b3b",
        "warning/contrastText": "#ffffff",
        "warning/hover": "#ea580c0a",
        "warning/selected": "#ea580c14",
        "warning/focusVisible": "#ea580c4d",
        "warning/outlinedBorder": "#ea580c80",
        "info/main": "#0284c7",
        "info/dark": "#026495",
        "info/light": "#10aefd",
        "info/contrastText": "#ffffff",
        "info/hover": "#0285c70a",
        "info/selected": "#0285c714",
        "info/focusVisible": "#0285c74d",
        "info/outlinedBorder": "#0285c780",
        "success/main": "#16a34a",
        "success/dark": "#117a37",
        "success/light": "#20e167",
        "success/contrastText": "#ffffff",
        "success/hover": "#16a34a0a",
        "success/selected": "#16a34a14",
        "success/focusVisible": "#16a34a4d",
        "success/outlinedBorder": "#16a34a80",
        "common/black/main": "#000000",
        "common/black/hover": "#0000000a",
        "common/black/selected": "#00000014",
        "common/black/focus": "#0000001f",
        "common/black/focusVisible": "#0000004d",
        "common/black/outlinedBorder": "#00000080",
        "common/white/main": "#ffffff",
        "common/white/hover": "#ffffff0a",
        "common/white/selected": "#ffffff14",
        "common/white/focus": "#ffffff1f",
        "common/white/focusVisible": "#ffffff4d",
        "common/white/outlinedBorder": "#ffffff80",
        "background/default": "#ffffff",
        "background/background-0": "#f9fafb",
        "background/background-1": "#f3f4f7",
        "background/background-2": "#ebedf2",
        "background/background-3": "#e4e7ed",
        "background/background-4": "#dce0e8",
        "background/background-5": "#d4d8e2",
        "background/background-6": "#cbd1dd",
        "background/background-7": "#c3cad7",
        "background/background-8": "#bbc2d2",
        "background/background-9": "#b3bbcc",
        "background/background-10": "#acb5c8",
        "background/background-11": "#9aa5bc",
        "background/background-12": "#8491ad",
        "background/background-13": "#7685a4",
        "background/background-14": "#6d7d9e",
        "background/background-15": "#627294",
        "background/background-16": "#5c6b8b",
        "background/background-17": "#53607d",
        "background/background-18": "#4d5974",
        "background/background-19": "#3e485e",
        "background/background-20": "#2f3747",
        "background/background-21": "#252b38",
        "background/background-22": "#12151b",
        "background/background-23": "#030304",
        "background/background-24": "#000000",
        "elevation/outlined": "#e4e7ed",
        divider: "#161a231f",
        "components/input/outlined/hoverBorder": "#a3a3a3",
        "components/input/outlined/enabledBorder": "#d6dae4",
        "components/input/standard/hoverBorder": "#a3a3a3",
        "components/input/standard/enabledBorder": "#acb5c875",
        "components/input/filled/hoverFill": "#acb5c833",
        "components/input/filled/enabledFill": "#acb5c826",
        "components/switch/knobFillEnabled": "#161a23de",
        "components/switch/slideFill": "#dce0e8",
        "components/switch/knowFillDisabled": "#161a2361",
        "components/rating/enabledBorder": "#0000003b",
        "components/rating/activeFill": "#fbbf24",
        "components/snackbar/fill": "#cbd1dd",
        "components/stepper/connector": "#9ca3af",
        "components/breadcrumbs/collapseFill": "#f3f4f6",
        "components/appBar/defaultFill": "#ffffff",
        "components/backdrop/fill": "#00000080",
        "components/tooltip/fill": "#616161e5",
        "components/chip/defaultEnabledBorder": "#9ca3af",
        "components/chip/defaultHoverFill": "#0000001f",
        "components/chip/defaultCloseFill": "#000000",
        "components/chip/defaultFocusFill": "#00000033",
        "components/alert/error/color": "#e11d48",
        "components/alert/error/background": "#fdedf1",
        "components/alert/info/color": "#0284c7",
        "components/alert/info/background": "#e7f7ff",
        "components/alert/warning/color": "#ea580c",
        "components/alert/warning/background": "#fef2ec",
        "components/alert/success/color": "#16a34a",
        "components/alert/success/background": "#e9fcf0",
        "components/avatar/fill": "#f9fafb",
        "_native/scrollbar-bg": "#e5e7eb",
        "inherit/main": "#f1f3f6de",
        "inherit/dark": "#d5dae5de",
        "inherit/light": "#f9fafbde",
        "inherit/contrastText": "#161a23de",
        "inherit/hover": "#f1f3f6de",
        "inherit/selected": "#161a2314",
        "inherit/focus": "#161a231f",
        "inherit/focusVisible": "#161a234d",
        "inherit/outlinedBorder": "#161a2380",
        "positive/main": "#059669",
        "positive/dark": "#04714e",
        "positive/light": "#08e19c",
        "positive/contrastText": "#ffffff",
        "positive/hover": "#0596680a",
        "positive/selected": "#05966814",
        "positive/focusVisible": "#0596684d",
        "positive/outlinedBorder": "#05966880",
        "negative/main": "#dc2626",
        "negative/dark": "#a61b1b",
        "negative/light": "#e45656",
        "negative/contrastText": "#ffffff",
        "negative/hover": "#dc26260a",
        "negative/selected": "#dc262614",
        "negative/focusVisible": "#dc26264d",
        "negative/outlinedBorder": "#dc262680",
        "buy/main": "#2563eb",
        "buy/dark": "#1147bb",
        "buy/light": "#5586ef",
        "buy/contrastText": "#ffffff",
        "buy/hover": "#2564eb14",
        "buy/selected": "#2564eb14",
        "buy/focusVisible": "#2564eb4d",
        "buy/outlinedBorder": "#2564eb80",
        "sell/main": "#dc2626",
        "sell/dark": "#a61b1b",
        "sell/light": "#e45656",
        "sell/contrastText": "#ffffff",
        "sell/hover": "#dc262614",
        "sell/selected": "#dc262614",
        "sell/focusVisible": "#dc26264d",
        "sell/outlinedBorder": "#dc262680",
        default: 12,
        none: 0,
        "paragraphIndent/0": 0,
        "containers/bento-background": "#f9fafb",
        "containers/bento-0": "#ffffff",
        "containers/bento-1": "#f3f4f7",
      },
      "Adaptive-Dark": {
        "1": 8,
        "2": 16,
        "3": 24,
        "4": 32,
        "5": 40,
        "6": 48,
        "7": 56,
        "8": 64,
        "9": 72,
        "10": 80,
        "11": 88,
        "12": 96,
        "text/primary": "#ffffffde",
        "text/secondary": "#ffffff80",
        "text/disabled": "#ffffff61",
        "text/hover": "#ffffff14",
        "text/selected": "#ffffff29",
        "text/focus": "#ffffff1f",
        "text/focusVisible": "#ffffff4d",
        "primary/main": "#3d82f6",
        "primary/dark": "#0a57dc",
        "primary/light": "#689df8",
        "primary/contrastText": "#ffffff",
        "primary/hover": "#3d81f614",
        "primary/selected": "#3d81f629",
        "primary/focus": "#3d81f61f",
        "primary/focusVisible": "#3d81f64d",
        "primary/outlinedBorder": "#3d81f680",
        "secondary/main": "#1f242e",
        "secondary/dark": "#1d212a",
        "secondary/light": "#0f1217",
        "secondary/contrastText": "#ffffff",
        "secondary/hover": "#1f242e0a",
        "secondary/selected": "#1f242e14",
        "secondary/focus": "#1f242e1f",
        "secondary/focusVisible": "#1f242e4d",
        "secondary/outlinedBorder": "#161a23",
        "action/active": "#ffffff8f",
        "action/hover": "#ffffff14",
        "action/selected": "#ffffff29",
        "action/focus": "#ffffff1f",
        "action/disabled": "#ffffff61",
        "action/disabledBackground": "#ffffff1f",
        "error/main": "#e11d48",
        "error/dark": "#a91636",
        "error/light": "#e84e6f",
        "error/contrastText": "#ffffff",
        "error/hover": "#e11d4714",
        "error/selected": "#e11d4729",
        "error/focusVisible": "#e11d474d",
        "error/outlinedBorder": "#e11d4780",
        "warning/main": "#ea580c",
        "warning/dark": "#b04209",
        "warning/light": "#f57b3b",
        "warning/contrastText": "#ffffff",
        "warning/hover": "#ea580c14",
        "warning/selected": "#ea580c29",
        "warning/focusVisible": "#ea580c4d",
        "warning/outlinedBorder": "#ea580c80",
        "info/main": "#0284c7",
        "info/dark": "#026495",
        "info/light": "#10aefd",
        "info/contrastText": "#ffffff",
        "info/hover": "#0285c70a",
        "info/selected": "#0285c714",
        "info/focusVisible": "#0285c74d",
        "info/outlinedBorder": "#0285c780",
        "success/main": "#16a34a",
        "success/dark": "#117a37",
        "success/light": "#20e167",
        "success/contrastText": "#ffffff",
        "success/hover": "#16a34a0a",
        "success/selected": "#16a34a14",
        "success/focusVisible": "#16a34a4d",
        "success/outlinedBorder": "#16a34a80",
        "common/black/main": "#000000",
        "common/black/hover": "#00000014",
        "common/black/selected": "#00000029",
        "common/black/focus": "#0000001f",
        "common/black/focusVisible": "#0000004d",
        "common/black/outlinedBorder": "#00000080",
        "common/white/main": "#ffffff",
        "common/white/hover": "#ffffff14",
        "common/white/selected": "#ffffff29",
        "common/white/focus": "#ffffff1f",
        "common/white/focusVisible": "#ffffff4d",
        "common/white/outlinedBorder": "#ffffff80",
        "background/default": "#161a23",
        "background/background-0": "#1b202b",
        "background/background-1": "#1f2431",
        "background/background-2": "#222836",
        "background/background-3": "#262c3c",
        "background/background-4": "#293041",
        "background/background-5": "#2e374a",
        "background/background-6": "#39435a",
        "background/background-7": "#404b66",
        "background/background-8": "#495674",
        "background/background-9": "#526082",
        "background/background-10": "#58688d",
        "background/background-11": "#5c6c92",
        "background/background-12": "#63759d",
        "background/background-13": "#6e7ea4",
        "background/background-14": "#7a88ab",
        "background/background-15": "#8592b2",
        "background/background-16": "#909cb9",
        "background/background-17": "#9ba6c0",
        "background/background-18": "#a6b0c7",
        "background/background-19": "#b1bace",
        "background/background-20": "#bcc4d5",
        "background/background-21": "#d3d7e3",
        "background/background-22": "#e9ebf1",
        "background/background-23": "#f4f5f8",
        "background/background-24": "#ffffff",
        "elevation/outlined": "#e4e7ed",
        divider: "#ffffff1f",
        "components/input/outlined/hoverBorder": "#cbd1dd",
        "components/input/outlined/enabledBorder": "#e4e7ed",
        "components/input/standard/hoverBorder": "#ffffff40",
        "components/input/standard/enabledBorder": "#ffffff1a",
        "components/input/filled/hoverFill": "#ebedf2",
        "components/input/filled/enabledFill": "#ffffff0f",
        "components/switch/knobFillEnabled": "#161a23de",
        "components/switch/slideFill": "#dce0e8",
        "components/switch/knowFillDisabled": "#161a2361",
        "components/rating/enabledBorder": "#ffffff3b",
        "components/rating/activeFill": "#fbbf24",
        "components/snackbar/fill": "#cbd1dd",
        "components/stepper/connector": "#4b5563",
        "components/breadcrumbs/collapseFill": "#4b5563",
        "components/appBar/defaultFill": "#161a23",
        "components/backdrop/fill": "#00000080",
        "components/tooltip/fill": "#616161e5",
        "components/chip/defaultEnabledBorder": "#cbd1dd",
        "components/chip/defaultHoverFill": "#ffffff1f",
        "components/chip/defaultCloseFill": "#ffffff",
        "components/chip/defaultFocusFill": "#ffffff33",
        "components/alert/error/color": "#e11d48",
        "components/alert/error/background": "#a916364d",
        "components/alert/info/color": "#0284c7",
        "components/alert/info/background": "#0264954d",
        "components/alert/warning/color": "#ea580c",
        "components/alert/warning/background": "#b042094d",
        "components/alert/success/color": "#16a34a",
        "components/alert/success/background": "#117a384d",
        "components/avatar/fill": "#292d35",
        "_native/scrollbar-bg": "#374151",
        "inherit/main": "#ffffff1a",
        "inherit/dark": "#262626de",
        "inherit/light": "#050505de",
        "inherit/contrastText": "#161a23de",
        "inherit/hover": "#ffffff0a",
        "inherit/selected": "#ffffff14",
        "inherit/focus": "#ffffff1f",
        "inherit/focusVisible": "#ffffff4d",
        "inherit/outlinedBorder": "#f1f3f6de",
        "positive/main": "#059669",
        "positive/dark": "#04714e",
        "positive/light": "#08e19c",
        "positive/contrastText": "#ffffff",
        "positive/hover": "#0596680a",
        "positive/selected": "#05966814",
        "positive/focusVisible": "#0596684d",
        "positive/outlinedBorder": "#05966880",
        "negative/main": "#dc2626",
        "negative/dark": "#a61b1b",
        "negative/light": "#e45656",
        "negative/contrastText": "#ffffff",
        "negative/hover": "#dc26260a",
        "negative/selected": "#dc262614",
        "negative/focusVisible": "#dc26264d",
        "negative/outlinedBorder": "#dc262680",
        "buy/main": "#2563eb",
        "buy/dark": "#1147bb",
        "buy/light": "#5586ef",
        "buy/contrastText": "#ffffff",
        "buy/hover": "#2564eb0a",
        "buy/selected": "#2564eb14",
        "buy/focusVisible": "#2564eb4d",
        "buy/outlinedBorder": "#2564eb80",
        "sell/main": "#dc2626",
        "sell/dark": "#a61b1b",
        "sell/light": "#e45656",
        "sell/contrastText": "#ffffff",
        "sell/hover": "#dc26260a",
        "sell/selected": "#dc262614",
        "sell/focusVisible": "#dc26264d",
        "sell/outlinedBorder": "#dc262680",
        default: 12,
        none: 0,
        "paragraphIndent/0": 0,
        "containers/bento-background": "#ffffff",
        "containers/bento-0": "#f9fafb",
        "containers/bento-1": "#f3f4f7",
      },
      "Alpaca-Light": {
        "1": 8,
        "2": 16,
        "3": 24,
        "4": 32,
        "5": 40,
        "6": 48,
        "7": 56,
        "8": 64,
        "9": 72,
        "10": 80,
        "11": 88,
        "12": 96,
        "text/primary": "#0a0a0ade",
        "text/secondary": "#0a0a0a99",
        "text/disabled": "#0a0a0a61",
        "text/hover": "#0a0a0a0a",
        "text/selected": "#0a0a0a14",
        "text/focus": "#0a0a0a1f",
        "text/focusVisible": "#0a0a0a4d",
        "primary/main": "#000000",
        "primary/dark": "#000000",
        "primary/light": "#383838",
        "primary/contrastText": "#ffffff",
        "primary/hover": "#0000000a",
        "primary/selected": "#00000014",
        "primary/focus": "#0000001f",
        "primary/focusVisible": "#0000004d",
        "primary/outlinedBorder": "#00000080",
        "secondary/main": "#737373",
        "secondary/dark": "#0c0c0c",
        "secondary/light": "#929292",
        "secondary/contrastText": "#ffffff",
        "secondary/hover": "#7373730a",
        "secondary/selected": "#73737314",
        "secondary/focus": "#7373731f",
        "secondary/focusVisible": "#7373734d",
        "secondary/outlinedBorder": "#73737380",
        "action/active": "#0a0a0a8f",
        "action/hover": "#0a0a0a0a",
        "action/selected": "#0a0a0a14",
        "action/focus": "#0a0a0a1f",
        "action/disabled": "#0a0a0a61",
        "action/disabledBackground": "#0a0a0a1f",
        "error/main": "#e11d48",
        "error/dark": "#a91636",
        "error/light": "#e84e6f",
        "error/contrastText": "#ffffff",
        "error/hover": "#e11d470a",
        "error/selected": "#e11d4714",
        "error/focusVisible": "#e11d474d",
        "error/outlinedBorder": "#e11d4780",
        "warning/main": "#ea580c",
        "warning/dark": "#b04209",
        "warning/light": "#f57b3b",
        "warning/contrastText": "#ffffff",
        "warning/hover": "#ea580c0a",
        "warning/selected": "#ea580c14",
        "warning/focusVisible": "#ea580c4d",
        "warning/outlinedBorder": "#ea580c80",
        "info/main": "#0284c7",
        "info/dark": "#026495",
        "info/light": "#10aefd",
        "info/contrastText": "#ffffff",
        "info/hover": "#0285c70a",
        "info/selected": "#0285c714",
        "info/focusVisible": "#0285c74d",
        "info/outlinedBorder": "#0285c780",
        "success/main": "#16a34a",
        "success/dark": "#117a37",
        "success/light": "#20e167",
        "success/contrastText": "#ffffff",
        "success/hover": "#16a34a0a",
        "success/selected": "#16a34a14",
        "success/focusVisible": "#16a34a4d",
        "success/outlinedBorder": "#16a34a80",
        "common/black/main": "#000000",
        "common/black/hover": "#0000000a",
        "common/black/selected": "#00000014",
        "common/black/focus": "#0000001f",
        "common/black/focusVisible": "#0000004d",
        "common/black/outlinedBorder": "#00000080",
        "common/white/main": "#ffffff",
        "common/white/hover": "#ffffff0a",
        "common/white/selected": "#ffffff14",
        "common/white/focus": "#ffffff1f",
        "common/white/focusVisible": "#ffffff4d",
        "common/white/outlinedBorder": "#ffffff80",
        "background/default": "#ffffff",
        "background/background-0": "#f9f9f9",
        "background/background-1": "#f2f2f2",
        "background/background-2": "#e9e9e9",
        "background/background-3": "#e2e2e2",
        "background/background-4": "#d8d8d8",
        "background/background-5": "#cfcfcf",
        "background/background-6": "#c6c6c6",
        "background/background-7": "#bdbdbd",
        "background/background-8": "#b4b4b4",
        "background/background-9": "#aaaaaa",
        "background/background-10": "#a3a3a3",
        "background/background-11": "#969696",
        "background/background-12": "#868686",
        "background/background-13": "#7c7c7c",
        "background/background-14": "#757575",
        "background/background-15": "#6c6c6c",
        "background/background-16": "#656565",
        "background/background-17": "#5b5b5b",
        "background/background-18": "#555555",
        "background/background-19": "#444444",
        "background/background-20": "#343434",
        "background/background-21": "#292929",
        "background/background-22": "#141414",
        "background/background-23": "#030303",
        "background/background-24": "#000000",
        "elevation/outlined": "#e4e7ed",
        divider: "#0a0a0a1f",
        "components/input/outlined/hoverBorder": "#a3a3a3",
        "components/input/outlined/enabledBorder": "#d1d1d1",
        "components/input/standard/hoverBorder": "#a3a3a3",
        "components/input/standard/enabledBorder": "#a3a3a375",
        "components/input/filled/hoverFill": "#a3a3a333",
        "components/input/filled/enabledFill": "#a3a3a326",
        "components/switch/knobFillEnabled": "#161a23de",
        "components/switch/slideFill": "#dce0e8",
        "components/switch/knowFillDisabled": "#161a2361",
        "components/rating/enabledBorder": "#0000003b",
        "components/rating/activeFill": "#fbbf24",
        "components/snackbar/fill": "#cbd1dd",
        "components/stepper/connector": "#9ca3af",
        "components/breadcrumbs/collapseFill": "#f3f4f6",
        "components/appBar/defaultFill": "#ffffff",
        "components/backdrop/fill": "#00000080",
        "components/tooltip/fill": "#616161e5",
        "components/chip/defaultEnabledBorder": "#9ca3af",
        "components/chip/defaultHoverFill": "#0000001f",
        "components/chip/defaultCloseFill": "#000000",
        "components/chip/defaultFocusFill": "#00000033",
        "components/alert/error/color": "#e11d48",
        "components/alert/error/background": "#fdedf1",
        "components/alert/info/color": "#0284c7",
        "components/alert/info/background": "#e7f7ff",
        "components/alert/warning/color": "#ea580c",
        "components/alert/warning/background": "#fef2ec",
        "components/alert/success/color": "#16a34a",
        "components/alert/success/background": "#e9fcf0",
        "components/avatar/fill": "#f9fafb",
        "_native/scrollbar-bg": "#e5e7eb",
        "inherit/main": "#f3f3f3de",
        "inherit/dark": "#dadadade",
        "inherit/light": "#fafafade",
        "inherit/contrastText": "#161a23de",
        "inherit/hover": "#f3f3f3de",
        "inherit/selected": "#0a0a0a14",
        "inherit/focus": "#0a0a0a1f",
        "inherit/focusVisible": "#0a0a0a4d",
        "inherit/outlinedBorder": "#0a0a0a80",
        "positive/main": "#059669",
        "positive/dark": "#04714e",
        "positive/light": "#08e19c",
        "positive/contrastText": "#ffffff",
        "positive/hover": "#0596680a",
        "positive/selected": "#05966814",
        "positive/focusVisible": "#0596684d",
        "positive/outlinedBorder": "#05966880",
        "negative/main": "#dc2626",
        "negative/dark": "#a61b1b",
        "negative/light": "#e45656",
        "negative/contrastText": "#ffffff",
        "negative/hover": "#dc26260a",
        "negative/selected": "#dc262614",
        "negative/focusVisible": "#dc26264d",
        "negative/outlinedBorder": "#dc262680",
        "buy/main": "#2563eb",
        "buy/dark": "#1147bb",
        "buy/light": "#5586ef",
        "buy/contrastText": "#ffffff",
        "buy/hover": "#2564eb14",
        "buy/selected": "#2564eb14",
        "buy/focusVisible": "#2564eb4d",
        "buy/outlinedBorder": "#2564eb80",
        "sell/main": "#dc2626",
        "sell/dark": "#a61b1b",
        "sell/light": "#e45656",
        "sell/contrastText": "#ffffff",
        "sell/hover": "#dc262614",
        "sell/selected": "#dc262614",
        "sell/focusVisible": "#dc26264d",
        "sell/outlinedBorder": "#dc262680",
        default: 12,
        none: 0,
        "paragraphIndent/0": 0,
        "containers/bento-background": "#f9fafb",
        "containers/bento-0": "#ffffff",
        "containers/bento-1": "#f3f4f7",
      },
      "Alpaca-Dark": {
        "1": 8,
        "2": 16,
        "3": 24,
        "4": 32,
        "5": 40,
        "6": 48,
        "7": 56,
        "8": 64,
        "9": 72,
        "10": 80,
        "11": 88,
        "12": 96,
        "text/primary": "#ffffffde",
        "text/secondary": "#ffffff80",
        "text/disabled": "#ffffff61",
        "text/hover": "#ffffff14",
        "text/selected": "#ffffff29",
        "text/focus": "#ffffff1f",
        "text/focusVisible": "#ffffff4d",
        "primary/main": "#facc15",
        "primary/dark": "#c7a004",
        "primary/light": "#fbd748",
        "primary/contrastText": "#131313",
        "primary/hover": "#facc1514",
        "primary/selected": "#facc1529",
        "primary/focus": "#facc151f",
        "primary/focusVisible": "#facc154d",
        "primary/outlinedBorder": "#facc1580",
        "secondary/main": "#171717",
        "secondary/dark": "#151515",
        "secondary/light": "#0c0c0c",
        "secondary/contrastText": "#ffffff",
        "secondary/hover": "#1717170a",
        "secondary/selected": "#17171714",
        "secondary/focus": "#1717171f",
        "secondary/focusVisible": "#1717174d",
        "secondary/outlinedBorder": "#161a23",
        "action/active": "#ffffff8f",
        "action/hover": "#ffffff14",
        "action/selected": "#ffffff29",
        "action/focus": "#ffffff1f",
        "action/disabled": "#ffffff61",
        "action/disabledBackground": "#ffffff1f",
        "error/main": "#e11d48",
        "error/dark": "#a91636",
        "error/light": "#e84e6f",
        "error/contrastText": "#ffffff",
        "error/hover": "#e11d4714",
        "error/selected": "#e11d4729",
        "error/focusVisible": "#e11d474d",
        "error/outlinedBorder": "#e11d4780",
        "warning/main": "#ea580c",
        "warning/dark": "#b04209",
        "warning/light": "#f57b3b",
        "warning/contrastText": "#ffffff",
        "warning/hover": "#ea580c14",
        "warning/selected": "#ea580c29",
        "warning/focusVisible": "#ea580c4d",
        "warning/outlinedBorder": "#ea580c80",
        "info/main": "#0284c7",
        "info/dark": "#026495",
        "info/light": "#10aefd",
        "info/contrastText": "#ffffff",
        "info/hover": "#0285c70a",
        "info/selected": "#0285c714",
        "info/focusVisible": "#0285c74d",
        "info/outlinedBorder": "#0285c780",
        "success/main": "#16a34a",
        "success/dark": "#117a37",
        "success/light": "#20e167",
        "success/contrastText": "#ffffff",
        "success/hover": "#16a34a0a",
        "success/selected": "#16a34a14",
        "success/focusVisible": "#16a34a4d",
        "success/outlinedBorder": "#16a34a80",
        "common/black/main": "#000000",
        "common/black/hover": "#00000014",
        "common/black/selected": "#00000029",
        "common/black/focus": "#0000001f",
        "common/black/focusVisible": "#0000004d",
        "common/black/outlinedBorder": "#00000080",
        "common/white/main": "#ffffff",
        "common/white/hover": "#ffffff14",
        "common/white/selected": "#ffffff29",
        "common/white/focus": "#ffffff1f",
        "common/white/focusVisible": "#ffffff4d",
        "common/white/outlinedBorder": "#ffffff80",
        "background/default": "#0a0a0a",
        "background/background-0": "#111111",
        "background/background-1": "#161616",
        "background/background-2": "#1b1b1b",
        "background/background-3": "#202020",
        "background/background-4": "#252525",
        "background/background-5": "#2c2c2c",
        "background/background-6": "#3b3b3b",
        "background/background-7": "#454545",
        "background/background-8": "#515151",
        "background/background-9": "#5d5d5d",
        "background/background-10": "#676767",
        "background/background-11": "#6c6c6c",
        "background/background-12": "#767676",
        "background/background-13": "#808080",
        "background/background-14": "#898989",
        "background/background-15": "#939393",
        "background/background-16": "#9d9d9d",
        "background/background-17": "#a7a7a7",
        "background/background-18": "#b1b1b1",
        "background/background-19": "#bababa",
        "background/background-20": "#c4c4c4",
        "background/background-21": "#d8d8d8",
        "background/background-22": "#ebebeb",
        "background/background-23": "#f5f5f5",
        "background/background-24": "#ffffff",
        "elevation/outlined": "#e4e7ed",
        divider: "#ffffff1f",
        "components/input/outlined/hoverBorder": "#cbd1dd",
        "components/input/outlined/enabledBorder": "#e4e7ed",
        "components/input/standard/hoverBorder": "#ffffff40",
        "components/input/standard/enabledBorder": "#ffffff1a",
        "components/input/filled/hoverFill": "#ebedf2",
        "components/input/filled/enabledFill": "#ffffff0f",
        "components/switch/knobFillEnabled": "#161a23de",
        "components/switch/slideFill": "#dce0e8",
        "components/switch/knowFillDisabled": "#161a2361",
        "components/rating/enabledBorder": "#ffffff3b",
        "components/rating/activeFill": "#fbbf24",
        "components/snackbar/fill": "#cbd1dd",
        "components/stepper/connector": "#4b5563",
        "components/breadcrumbs/collapseFill": "#4b5563",
        "components/appBar/defaultFill": "#0a0a0a",
        "components/backdrop/fill": "#00000080",
        "components/tooltip/fill": "#616161e5",
        "components/chip/defaultEnabledBorder": "#cbd1dd",
        "components/chip/defaultHoverFill": "#ffffff1f",
        "components/chip/defaultCloseFill": "#ffffff",
        "components/chip/defaultFocusFill": "#ffffff33",
        "components/alert/error/color": "#e11d48",
        "components/alert/error/background": "#a916364d",
        "components/alert/info/color": "#0284c7",
        "components/alert/info/background": "#0264954d",
        "components/alert/warning/color": "#ea580c",
        "components/alert/warning/background": "#b042094d",
        "components/alert/success/color": "#16a34a",
        "components/alert/success/background": "#117a384d",
        "components/avatar/fill": "#212121",
        "_native/scrollbar-bg": "#374151",
        "inherit/main": "#ffffff1a",
        "inherit/dark": "#262626de",
        "inherit/light": "#050505de",
        "inherit/contrastText": "#161a23de",
        "inherit/hover": "#ffffff0a",
        "inherit/selected": "#ffffff14",
        "inherit/focus": "#ffffff1f",
        "inherit/focusVisible": "#ffffff4d",
        "inherit/outlinedBorder": "#f1f3f6de",
        "positive/main": "#059669",
        "positive/dark": "#04714e",
        "positive/light": "#08e19c",
        "positive/contrastText": "#ffffff",
        "positive/hover": "#0596680a",
        "positive/selected": "#05966814",
        "positive/focusVisible": "#0596684d",
        "positive/outlinedBorder": "#05966880",
        "negative/main": "#dc2626",
        "negative/dark": "#a61b1b",
        "negative/light": "#e45656",
        "negative/contrastText": "#ffffff",
        "negative/hover": "#dc26260a",
        "negative/selected": "#dc262614",
        "negative/focusVisible": "#dc26264d",
        "negative/outlinedBorder": "#dc262680",
        "buy/main": "#2563eb",
        "buy/dark": "#1147bb",
        "buy/light": "#5586ef",
        "buy/contrastText": "#ffffff",
        "buy/hover": "#2564eb0a",
        "buy/selected": "#2564eb14",
        "buy/focusVisible": "#2564eb4d",
        "buy/outlinedBorder": "#2564eb80",
        "sell/main": "#dc2626",
        "sell/dark": "#a61b1b",
        "sell/light": "#e45656",
        "sell/contrastText": "#ffffff",
        "sell/hover": "#dc26260a",
        "sell/selected": "#dc262614",
        "sell/focusVisible": "#dc26264d",
        "sell/outlinedBorder": "#dc262680",
        default: 12,
        none: 0,
        "paragraphIndent/0": 0,
        "containers/bento-background": "#ffffff",
        "containers/bento-0": "#f9fafb",
        "containers/bento-1": "#f3f4f7",
      },
    },
  },
}

type GenTheme_VariablesByCollection = GenTheme_Collection1 &
  GenTheme_Collection2
type GenTheme_Collection1 = GenTheme_Collection<
  "Brand",
  "Alpaca" | "Adaptive",
  {
    "brd/controller/spacing/base": number
    "brd/controller/sizing/base": number
    "brd/controller/sizing/xs": number
    "brd/controller/sizing/sm": number
    "brd/controller/sizing/md": number
    "brd/controller/sizing/lg": number
    "brd/controller/borderRadius/default": number
    "brd/controller/borderRadius/none": number
    "brd/controller/borderRadius/sm": number
    "brd/controller/borderRadius/md": number
    "brd/controller/borderRadius/lg": number
    "brd/controller/borderRadius/full": number
    "brd/controllers/brand/base": string
    "brd/controllers/mainUI/dark/background": string
    "brd/controllers/mainUI/light/background": string
    "brd/controllers/mainUI/dark/text-base": string
    "brd/controllers/mainUI/light/text-base": string
    "brd/controllers/functionalColors/info": string
    "brd/controllers/functionalColors/error": string
    "brd/controllers/functionalColors/success": string
    "brd/controllers/functionalColors/warning": string
    "brd/controllers/finance/positive": string
    "brd/controllers/finance/negative": string
    "brd/controllers/finance/buy": string
    "brd/controllers/finance/sell": string
    "brd/controllers/borderWidth": number
    "brd/controllers/colorSplash/light/primary": string
    "brd/controllers/colorSplash/dark/primary": string
    "brd/controllers/colorSplash/light/secondary": string
    "brd/controllers/colorSplash/dark/secondary": string
    "brd/controllers/colorSplash/light/neutral": string
    "brd/controllers/colorSplash/dark/neutral": string
    "brd/controllers/colorSplash/light/primary-text-base": string
    "brd/controllers/colorSplash/dark/primary-text-base": string
  }
>
type GenTheme_Collection2 = GenTheme_Collection<
  "Theme",
  "Adaptive-Light" | "Adaptive-Dark" | "Alpaca-Light" | "Alpaca-Dark",
  {
    "1": number
    "2": number
    "3": number
    "4": number
    "5": number
    "6": number
    "7": number
    "8": number
    "9": number
    "10": number
    "11": number
    "12": number
    "text/primary": string
    "text/secondary": string
    "text/disabled": string
    "text/hover": string
    "text/selected": string
    "text/focus": string
    "text/focusVisible": string
    "primary/main": string
    "primary/dark": string
    "primary/light": string
    "primary/contrastText": string
    "primary/hover": string
    "primary/selected": string
    "primary/focus": string
    "primary/focusVisible": string
    "primary/outlinedBorder": string
    "secondary/main": string
    "secondary/dark": string
    "secondary/light": string
    "secondary/contrastText": string
    "secondary/hover": string
    "secondary/selected": string
    "secondary/focus": string
    "secondary/focusVisible": string
    "secondary/outlinedBorder": string
    "action/active": string
    "action/hover": string
    "action/selected": string
    "action/focus": string
    "action/disabled": string
    "action/disabledBackground": string
    "error/main": string
    "error/dark": string
    "error/light": string
    "error/contrastText": string
    "error/hover": string
    "error/selected": string
    "error/focusVisible": string
    "error/outlinedBorder": string
    "warning/main": string
    "warning/dark": string
    "warning/light": string
    "warning/contrastText": string
    "warning/hover": string
    "warning/selected": string
    "warning/focusVisible": string
    "warning/outlinedBorder": string
    "info/main": string
    "info/dark": string
    "info/light": string
    "info/contrastText": string
    "info/hover": string
    "info/selected": string
    "info/focusVisible": string
    "info/outlinedBorder": string
    "success/main": string
    "success/dark": string
    "success/light": string
    "success/contrastText": string
    "success/hover": string
    "success/selected": string
    "success/focusVisible": string
    "success/outlinedBorder": string
    "common/black/main": string
    "common/black/hover": string
    "common/black/selected": string
    "common/black/focus": string
    "common/black/focusVisible": string
    "common/black/outlinedBorder": string
    "common/white/main": string
    "common/white/hover": string
    "common/white/selected": string
    "common/white/focus": string
    "common/white/focusVisible": string
    "common/white/outlinedBorder": string
    "background/default": string
    "background/background-0": string
    "background/background-1": string
    "background/background-2": string
    "background/background-3": string
    "background/background-4": string
    "background/background-5": string
    "background/background-6": string
    "background/background-7": string
    "background/background-8": string
    "background/background-9": string
    "background/background-10": string
    "background/background-11": string
    "background/background-12": string
    "background/background-13": string
    "background/background-14": string
    "background/background-15": string
    "background/background-16": string
    "background/background-17": string
    "background/background-18": string
    "background/background-19": string
    "background/background-20": string
    "background/background-21": string
    "background/background-22": string
    "background/background-23": string
    "background/background-24": string
    "elevation/outlined": string
    divider: string
    "components/input/outlined/hoverBorder": string
    "components/input/outlined/enabledBorder": string
    "components/input/standard/hoverBorder": string
    "components/input/standard/enabledBorder": string
    "components/input/filled/hoverFill": string
    "components/input/filled/enabledFill": string
    "components/switch/knobFillEnabled": string
    "components/switch/slideFill": string
    "components/switch/knowFillDisabled": string
    "components/rating/enabledBorder": string
    "components/rating/activeFill": string
    "components/snackbar/fill": string
    "components/stepper/connector": string
    "components/breadcrumbs/collapseFill": string
    "components/appBar/defaultFill": string
    "components/backdrop/fill": string
    "components/tooltip/fill": string
    "components/chip/defaultEnabledBorder": string
    "components/chip/defaultHoverFill": string
    "components/chip/defaultCloseFill": string
    "components/chip/defaultFocusFill": string
    "components/alert/error/color": string
    "components/alert/error/background": string
    "components/alert/info/color": string
    "components/alert/info/background": string
    "components/alert/warning/color": string
    "components/alert/warning/background": string
    "components/alert/success/color": string
    "components/alert/success/background": string
    "components/avatar/fill": string
    "_native/scrollbar-bg": string
    "inherit/main": string
    "inherit/dark": string
    "inherit/light": string
    "inherit/contrastText": string
    "inherit/hover": string
    "inherit/selected": string
    "inherit/focus": string
    "inherit/focusVisible": string
    "inherit/outlinedBorder": string
    "positive/main": string
    "positive/dark": string
    "positive/light": string
    "positive/contrastText": string
    "positive/hover": string
    "positive/selected": string
    "positive/focusVisible": string
    "positive/outlinedBorder": string
    "negative/main": string
    "negative/dark": string
    "negative/light": string
    "negative/contrastText": string
    "negative/hover": string
    "negative/selected": string
    "negative/focusVisible": string
    "negative/outlinedBorder": string
    "buy/main": string
    "buy/dark": string
    "buy/light": string
    "buy/contrastText": string
    "buy/hover": string
    "buy/selected": string
    "buy/focusVisible": string
    "buy/outlinedBorder": string
    "sell/main": string
    "sell/dark": string
    "sell/light": string
    "sell/contrastText": string
    "sell/hover": string
    "sell/selected": string
    "sell/focusVisible": string
    "sell/outlinedBorder": string
    default: number
    none: number
    "paragraphIndent/0": number
    "containers/bento-background": string
    "containers/bento-0": string
    "containers/bento-1": string
  }
>

type GenTheme_Collection<
  TName extends string,
  TMode extends string,
  TVariables,
> = {
  [name in TName]: {
    defaultModeName: TMode
    modeNames: TMode[]
    modes: {
      [mode in TMode]: TVariables
    }
  }
}

type GenTheme_TextStyleBindings = Record<
  string,
  Record<string, TT_BoundVariable>
>

type TT_BoundVariable = {
  fieldName: string
  collectionName: string
  variableName: string
}

type TT_TextDecoration = "none" | "underline" | "line-through"

type TT_TextTransform =
  | TT_Globals
  | "capitalize"
  | "full-size-kana"
  | "full-width"
  | "lowercase"
  | "none"
  | "uppercase"

type TT_Globals =
  | "-moz-initial"
  | "inherit"
  | "initial"
  | "revert"
  | "revert-layer"
  | "unset"
