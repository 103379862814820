import { createTheme, PaletteOptions } from "@mui/material/styles"
import { TypographyOptions } from "@mui/material/styles/createTypography"

import { components } from "./components"
import { constants } from "./constants"
import { resolveTheme } from "./generatedTheme"

type AlpacaTheme = ReturnType<typeof resolveTheme>
type AlpacaPaletteKey = keyof AlpacaTheme["variables"]["Theme"]

const convertPalette = (theme: AlpacaTheme) =>
  Object.keys(theme.variables.Theme).reduce(
    (accum: Record<string, Record<string, string>>, key: AlpacaPaletteKey) => {
      const value = theme.variables.Theme[key] as string
      if (key.includes("/")) {
        const keys = key.split("/")
        const rootKey = keys[0] as AlpacaPaletteKey
        const subkey = keys[1].replace("background-", "background")
        if (accum[rootKey] === undefined) {
          accum[rootKey] = {}
        }
        accum[rootKey][subkey] = value
      }
      return accum
    },
    {} as Record<string, Record<string, string>>,
  )

const convertTextStyles = (theme: AlpacaTheme) =>
  Object.keys(theme.textStyles).reduce(
    (accum: Record<string, Record<string, string>>, key: string) => {
      const value = theme.textStyles[key] as string
      if (key.includes("/")) {
        const keys = key.split("/")
        const rootKey = keys[0]
        const subkey = keys[1]
        if (accum[rootKey] === undefined) {
          accum[rootKey] = {}
        }
        accum[rootKey][subkey] = value
      }
      return accum
    },
    {} as Record<string, Record<string, string>>,
  )

const genDarkTheme = resolveTheme({ Theme: "Alpaca-Dark" })
const genLightTheme = resolveTheme({ Theme: "Alpaca-Light" })

const darkTheme = createTheme(
  {
    components,
    palette: {
      mode: "dark",
      ...(convertPalette(genDarkTheme) as PaletteOptions),
    },
    typography: convertTextStyles(genDarkTheme).typography as TypographyOptions,
  },
  { constants, ...genDarkTheme },
)

const lightTheme = createTheme(
  {
    components,
    palette: {
      mode: "light",
      ...(convertPalette(genLightTheme) as PaletteOptions),
    },
    typography: convertTextStyles(genLightTheme)
      .typography as TypographyOptions,
  },
  { constants, ...genLightTheme },
)

export const themes = { light: lightTheme, dark: darkTheme }
